import Vue from 'vue'
import { getCheckCode, register } from '../../api/registerApi'
import { mapState, mapActions } from 'vuex'


export default {
  components: {},
  data () {
    return {
      userFirmname: '',//身份证姓名
      userIdCard: '',//身份证号
    }
  },
  created () {
    this.getName()
  },
  methods: {
    ...mapActions('home', ['smrzInfo']),
    back () {
      this.$router.go(-1)
    },
    getName () {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.smrzInfo().then((res) => {
          this.userFirmname = res.data.userFirmname
          this.userIdCard = res.data.userIdCard

        })
      }
    }

  },
}
