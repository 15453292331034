import { post, post2 } from '../utils/request';

/**
 * 获取注册验证码
 * @param {*} params --请求参数
 */
export async function getCheckCode(params) {
  var getcode = '/system/user/sendCodeRegister/'+params.phone;
  return post(getcode,{});
}
// 获取其他验证码
export async function getCode(params) {
  var getcode1 = '/system/user/sendCode/'+params.phone;
  return post(getcode1,{});
}
// 修改密码接口editPassword
export async function editPassword(params) {
  return post2('/system/user/updatePassword', params);
}

/**
 * 注册接口
 * @param {*} params --请求参数
 */
export async function register(params) {
  return post2('/system/user/register', params);
}





// 新注册
/**
 * 获取注册验证码
 * @param {*} params --请求参数
 */
 export async function sendCode(params) {
  var sendCode2 = '/system/userTwo/sendCode/'+params.phone;
  return post(sendCode2,{});
}
/**
 * 注册接口
 * @param {*} params --请求参数
 */
 export async function newregister(params) {
  return post2('/system/userTwo/register', params);
}
